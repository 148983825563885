.customer-account-login {
  .block-customer-login {
    float: left;
    clear: left;
    width: 48%;


    .note {
      font-size: 13px;
    }

    .control {
      width: 55%;
    }

    .primary {
      margin-bottom: 0;
      display: inline-block;
      float: left;
    }

    .secondary {
      margin-bottom: 0;
      display: inline-block;
      text-align: center;

      .remind {
        display: inline-block;
        margin-top: 6px;
        font-size: 13px;

        &:hover {
          color: #337ab7;
        }
      }
    }

    &:after {
      clear: both;
      content: '';
      display: block;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  .block-new-customer {
    float: right;
    margin-bottom: 50px;
    width: 48%;

    .block-content {
      > p {
        font-size: 13px;
      }

      .actions-toolbar {
        margin-top: 25px;
      }
    }
  }

}