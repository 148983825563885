div.category-view {
  div.category-description {

    .description-content {
      font-size: 13px;
      height: 72px;
      line-height: 180%;
    }
    h1 {
      font-size: 45px;
    }
  }
}