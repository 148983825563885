.customer-address-index {

  .block-addresses-default {
    margin-bottom: 50px;

    .box-billing-address {
      float: left;
      width: 48%;
    }

    .box-address-billing {
      float: left;
      clear: left;
      width: 48%;
    }

    .box-address-shipping {
      float: right;
      width: 48%;
    }
  }

  .block-addresses-list {
    margin-bottom: 50px;

    .block-content {
      font-size: 16px;
      color: $ss-gray;

      .items.addresses {
        .item:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      .item.actions {
        .action {
          &:first-child:after {
            content: '';
            display: inline-block;
            height: 12px;
            margin: 0 10px;
            border-left: 1px solid #a6a6a6;
            vertical-align: -1px;
          }
        }
      }
    }
  }

}