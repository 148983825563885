.account,
.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword,
.customer-account-createpassword {
  .actions-toolbar {
    .action.primary {
      text-decoration: none;
      width: auto;
      margin: 0 15px 0 0;
      display: inline-block;
      background-image: none;
      background: $gold;
      border: 1px solid $gold;
      color: white;
      cursor: pointer;
      font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica, Arial, sans-serif';
      font-weight: 300;
      padding: 10px 50px;
      font-size: 13px;
      box-sizing: border-box;
      vertical-align: middle;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
        text-decoration: none;
      }
    }

    .action.back {
      display: none;
    }
  }
}