.homepage-sliders-switcher-content,
.product-sliders-switcher-content{
  margin: 35px 0 45px 0;
  .switcher-content{
    width: 100%;
    border-bottom: 2px solid $super-light-blue;
    //margin-bottom: 27px;
    #switcher-items-box{
      display: block;
      position: relative;
      top: 1px;
    }
    .switcher-item{
      display: inline-block;
      border-bottom: 2px solid #FFFFFF;
      padding: 18px;
      font-size: 13px;
      line-height: 1.0;
      text-transform: uppercase;
      font-weight: lighter;
    }
    .switcher-item.active{
      font-weight: bold;
      border-bottom: 3px solid $gold;
    }
    .switcher-item:hover{
      cursor: pointer;
    }
    .switcher-item.active:hover{
      cursor: default;
    }
  }
  .switcher-box{
    display: none;
  }
  .switcher-box.active{
    display: block;
  }
  .ajax-loader{
    text-align: center;
  }

  .switcher-border-bottom {
    display: block;
    border-bottom: 2px solid $super-light-blue;
  }
}

.product-sliders-switcher-content {
  padding: 8px 0 70px 0;
  margin: 0;
}