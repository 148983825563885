@mixin transition($time) {
  transition: $time;
  -webkit-transition: $time;
  -moz-transition: $time;
  -o-transition: $time;
}

$transform-center-vertical: translate(0, -50%);
$transform-center-horizontal: translate(-50%, 0);
$transform-center-both: translate(-50%, -50%);
@mixin transform-center($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}


@mixin transform($x, $y) {
  transform: translate($x, $y);
  -webkit-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
}

@mixin scale($value) {
  -ms-transform: scale($value);
  -webkit-transform: scale($value);
  transform: scale($value);
}

@mixin placeholder($color) {
  ::-webkit-input-placeholder {
    color: $color;
    font-weight: lighter;
  }
  ::-moz-placeholder {
    color: $color;
    font-weight: lighter;
  }
  :-ms-input-placeholder {
    color: $color;
    font-weight: lighter;
  }
  :-moz-placeholder {
    color: $color;
    font-weight: lighter;
  }
}

@mixin links($color, $colorHover, $hasHover: true) {
  > a {
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: $color;
    cursor: pointer;
    @if $hasHover == true {
      &:hover {
        color: $colorHover;
        @include transition(0.5s);
      }
    }
  }
}

@mixin social-icons($color, $background-color, $hoverBackground, $hasHover: true) {
  > li {
    float: left;
    width: 50px;
    height: 50px;
    background-color: $background-color;
    border-radius: 50%;
    > a {
      text-decoration: none;
      > i {
        display: block;
        padding: 14px 16px;
        font-size: 22px;
        color: $color;
        text-decoration: none;
        cursor: pointer;
      }
    }
    &:not(:last-child) {
      margin-right: 20px;
    }
    @if $hasHover == true {
      &:hover {
        background-color: $hoverBackground;
        @include transition(.7s);
      }
    }
  }
}