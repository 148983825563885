.banner-slider-content {
  margin: 0 0 45px 0;
  .item {
    .banner {
      &-content {
        height: $banner-l-height;
        padding: 10px 0;
      }

      &-container {
        height: 100%;
      }

      //left content
      &-left-content {
      }

      &-image {
        top: 50%;
        left: 50%;
        @include transform-center($transform-center-both);
      }

      //right content
      &-right-content {
      }

      &-center-content {
        padding-top: 190px;
      }

      &-title {
        font-size: 72px;
        line-height: 1.2;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.35);
      }

      &-description {
        font-size: 18px;
      }

      &-btn {
        padding: 18px 30px;
        background-color: $gold;
        color: #FFFFFF;
        display: inline-block;
        font-size: 16px;
        margin-top: 25px;
        font-weight: lighter;
        letter-spacing: 3px;
      }

      &-btn:hover {
        cursor: pointer;
      }
    }
  }
  .owl-controls {
    width: 100%;
    top: 220px;
    .owl-nav {
      .owl-prev,
      .owl-next {
        .nav-box {
          width: 55px;
          height: 112px;
          font-size: 19px;
          > * {
            margin: 30px 0;
          }
        }
      }

      @media screen and (min-width: $screen-lg)  {
        .owl-prev {
          margin-left: 15%;
        }

        .owl-next {
          margin-right: 15%;
        }
      }

    }
    .owl-dots {
      top: 275px;
      width: 100%;
      display: inline-block !important;
      .owl-dot {
        width: 36px;
        height: 4px;
      }
      .owl-dot.active {
        background-color: $gold;
      }
    }
  }
}