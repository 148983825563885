.blog-post {
  .social-button {
    top: -3px;
    position: relative;
    font-size: 16px;
    line-height: 1.0;
  }
  .blog-post__share-content {
    .social-button {
      a {
        padding: 0;
      }
    }
  }
  .blog-post__content {

    .col-l {
      width: 40%;
      padding-right: 15px;
    }
    .col-r {
      width: 60%;
      padding-left: 15px;
    }

    .blog-post__content-image {
      max-width: 100%;
    }
    .product__short-description {
      .image-href {
        display: inline;
        img {
          float: right;
          max-width: 40%;
          margin-right: 10px;
        }
      }
    }
  }
}
