%mega-menu-item-l {
  display: block !important;
  position: relative;
  //max-width: $mega-menu-max-width;
  margin: 0 auto;
  padding-bottom: 20px;
  visibility: hidden;
  background-color: white;
  opacity: 0;
  -moz-box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1), -2px 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1), -2px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1), -2px 3px 3px rgba(0, 0, 0, 0.1);
  @include transition(all .25s ease .07s);

  .top-bar {
    height: 50px;
    padding-left: 15px;
    color: white;
    font-weight: lighter;
    background-color: $gold;
    .category-link {
      font-size: 13px;
      .angle-right {
        position: relative;
        top: 1px;
        margin-right: 10px;
        font-size: 20px;
      }
      &:hover {
        color: $gray;
      }
    }
  }

  .content {
    padding-top: 15px;
    padding-bottom: 20px;
    position: relative;
    .close-menu {
      position: absolute;
      right: 0;
      top: 30px;
      cursor: pointer;
      width: 35px;
      height: 35px;
      background-color: $gold;
      color: white;
      font-size: 26px;
      font-weight: 500;
      text-align: center;
      line-height: 39px;
      @extend .btn-gold-grad;
    }
    .mega-menu-column {
      float: left;
      padding: 0 2.5% 2.5% 2.5%;
    }

    .mega-menu-column:not(:last-child) {
      width: 20%;
      .subcategory-link {
        margin: 20px 0 10px 0;
        line-height: 18px;
        a {
          font-size: 14px;
          color: $dark-gray;
          font-weight: bold;
          text-transform: uppercase;
          &:hover {
            color: $gold;
          }
        }
      }
      ul {
        list-style: none;
        padding-left: 0;
        li:not(.subcategory-link) {
          a {
            display: block;
            line-height: 30px;
            font-size: 12px;
            color: $dark-gray;
            &:hover {
              color: $gold;
              @include transition(all .2s ease-in-out);
              @include transform(5px, 0);
            }
          }
          &:last-child {
            margin-bottom: 20px;
          }
        }
      }
    }

    .mega-menu-column:last-child {
      width: 40%;
      margin-top: 15px;
      border-left: 1px solid $gray;
      .cms-content{
        .img-wrapper {
          img {
            width: 100%;
            height: auto;
          }
        }
        .title,
        .description {
          display: block;
          line-height: 1;
        }
        .title {
          margin: 30px 0;
          font-size: 19px;
          color: $lux-gray;
          font-weight: bold;
          text-transform: uppercase;
        }
        .description {
          font-size: 15px;
          color: $lux-gray;
          line-height: 1.5;
        }
        .btn {
          display: block;
          margin: 0 auto;
          margin-top: 25px;
          padding: 10px 35px;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          color: white;
          background-color: $gold;
          > a:hover {
            color: white;
          }
        }
      }
    }
    &:after {
      display: block;
      content: '';
      clear: both;
    }
  }
}