#category-table-contents {
  @media screen and (min-width: $screen-md) {
    .col-md-3 {
      padding-right: 0;
    }
    .table-content-sidebar {
      margin-bottom: 0;
      padding: 8px;
      &.affix {
        top: 170px;
        width: 227.5px;
        position: fixed;
      }
      &.affix-bottom {
        position: relative;
        width: 100%;
      }
      .table-content-sidebar-content {
        ul, ol {
          li {
            a {
              display: inline-block;
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            &.active {
              color: white;
              &:before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 100%;
                height: 26px;
                left: 0;
                background-color: $gold;
              }
              &:after {
                display: inline-block;
                content: "";
                position: absolute;
                left: 100%;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 13px 0 13px 12px;
                border-color: transparent transparent transparent $gold;
              }
            }
          }
        }
      }

    }
    .table-content-content {
      padding-left: 50px;
    }
  }
  @media screen and (min-width: $screen-lg) {
    .table-content-sidebar {
      &.affix {
        width: 277.5px;
      }
    }
  }
}