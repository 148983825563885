.catalog-product-view {
  .breadcrumbs-container {
    @extend .container;
  }
  .product-base-view{
    margin: 25px auto 45px auto;
  }
  .product-info-info{
    @media screen and (min-width: 992px){
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-radius: 5px 5px 0 0;
      border-color: $gray;
      padding: 15px 15px 15px 15px;
      float: right;
    }
    @media screen and (min-width: 1200px) {
      padding: 30px 30px 15px 30px;
    }
    .product-info-stock-sku {
      #product-name {
        margin: 0 0 20px 0;
      }
      .product-manufacturer {
        font-size: 12px;
        margin: 0;
      }
      .product-squ {
        margin: 0 0 26px 0;
      }
    }
  }

  .product-info-main {

    @media screen and (min-width: 992px){
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-radius: 0 0 5px 5px;
      padding: 0 15px 7px 15px;
      border-color: $gray;
    }
    @media screen and (min-width: 1200px){
      padding: 0 30px 7px 30px;
    }

    .price-box-wrapper {
      margin-top: 12px;
      text-align: left;
      .price-box {
        .old-price, .special-price {
          display: block;
        }
        .old-price {
          line-height: 1;
        }
      }
      p.our-price {
        margin-bottom: 10px;
      }
      p.price-tax {
      }
    }
    .product-add-form{
      .fieldset{
        .field.qty {
          display: inline-block;
          padding-right: 15px;
          height:100%;
          .qty-box{
            display: inline-block;
            max-width: 100%;
            min-width: 100px;
            height: 100%;
            background-color: $lighter-gray;
            color: $ss-dark-gray;
            font-size: 0;
            >* {
              display: inline-block;
              font-size: 40px;
              width: 33%;
              height: 100%;
              text-align: center;
              vertical-align: middle;
              padding: 5px;
            }
            .qty-plus:hover,
            .qty-minus:hover{
              cursor: pointer;
            }
            .input-text.qty.qty-center{
              border: none;
              background-color: transparent;
              text-align: center;
              -moz-appearance: textfield;
            }
            .input-text.qty::-webkit-inner-spin-button,
            .input-text.qty::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
        .actions{
          width: 100%;
          display: inline-block;
        }
        button.tocart {
          text-transform: uppercase;
          font-weight: 300;
          font-size: 14px;
        }
      }
    }
    .shipping-icons {
      & > div {
        @media screen and (min-width: 992px) and (max-width: 1199px){
          &:not(:last-child) {
            margin-right: 9px;
          }
          & > div {
            vertical-align: top;
          }
        }
      }
    }
  }
}