.hp-box-content{
  padding: 35px 0;
  .head-box{
    text-align: center;
    margin-bottom: 35px;
    .title{
      font-size: 19px;
      font-weight: bold;
      color: $custom-red;
      margin: 0 0 27px 0;
      text-transform: uppercase;
      line-height: 1.4;
    }
    .desc{
      font-size: 1.4em;
      font-weight: lighter;
      color: $dark-gray;
      line-height: 1.2;
    }
  }
  .container{
    .col-xs-12,
    .col-sm-12,
    .col-md-12,
    .col-lg-12{
      padding: 0 15px;
    }
  }
}
