.sales-order-view {

  .table-wrapper.order-items {
    margin-top: 15px;

    .table-order-items {
      .items-qty {
        .item {
          position: relative;
          top: 8px;
        }
      }
    }

    .subtotal {
      .amount {
        .price {
          position: relative;
          left: 12px;
        }
      }
    }

    .totals-tax {
      .amount {
        .price {
          position: relative;
          left: 12px;
        }
      }
    }

    .shipping {
      .amount {
        .price {
          position: relative;
          left: 12px;
        }
      }
    }

    .grand_total {
      .price {
        position: relative;
        left: 12px;
      }
    }
  }

}