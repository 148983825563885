// Global colors

//Luxustrade

$gold: #b79f7b;
$black: #211F20;
$light-black: #2d2e2f;
$super-light-blue: #f5f6f7;
$lux-gray: #a6a6a6;
$gray: #e3e3e3;
$black: #18191a;
$lux-red: #d35256;
$lux-green: #9ac988;
$lux-dark-blue: #49565b;
$method-bg: #f6f6f6;
$classic-silver: #ccc;

// Boilerplate stuff
$light-red: #fae5e5;
$custom-red: #ed1c24;

$custom-blue: #129fc8;
$custom-darker-blue: #1088ad;

$light-green: #e5efe5;
$custom-green: #9FBF0A;
$green: #4ae422;
$custom-green-dark: #669933;
$dark-green: #006400;

$brown: #6f4400;
$light-yellow: #fdf0d5;
$yellow: #fddc37;

$dark-gray: #696969;
$lighter-gray: #efefef;
$light-gray: #f5f5f5;

// Header
$expand-navbar-fixed-top-z-index: 1030;
$expand-navbar-hover: #1389ac;
$mega-menu-max-width: 1175px;
$mobile-menu-z-index: 1030;

// Footer
$footer-bg: #1e657a;
$brighter-gray: #77a3ae;
$darker-gray: #878787;
$newsletter-envelope-z-index: 1;

//Banner Slider
$banner-m-height: 150px;
$banner-l-height: 550px;


//Advanced Sliders
//Consu-Slider
$consul-l-height: 310px;
$consul-tb-l-height:100px;

$consul-m-height: 250px;
$consul-tb-m-height:80px;

//review
$star-active: #F4A62B;

//slider-switcer
$ss-dark-gray: #565656;
$ss-gray: #797979;
