.customer-account-create {

  .legend {
    border-bottom: none !important;

    span {
      font-size: 14px !important;
      color: black !important;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 500 !important;
    }
  }

  .form-create-account {
    width: 70% !important;
    min-width: 600px;
    padding: 40px;
  }

  .fieldset.create.account {
    margin-bottom: 20px;
  }

  .action.back {
    display: none;
  }

  .newsletter {
    .checkbox {
      margin-top: 10px !important;
    }

    .label {
      width: 30% !important;
    }
  }

  #password-strength-meter {
    font-weight: 500 !important;
  }

}