.page-footer {
  margin-top: 35px;
  padding: 45px 0;


  .footer-row {
    padding-bottom: 25px;
    height: 215px;
    border-bottom: 1px solid $lux-gray;
  }
  .contact-box {
    .logo {
      width: 145px;
      display: block;
      margin-bottom: 35px;
    }
    > ul {
      list-style-type: none;
      display: inline-block;
      padding-left: 0;
      margin: 0;
      > li {
        position: relative;
        font-weight: lighter;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        .contact {
          > a {
            text-decoration: none;
          }
          .phone {
            display: block;
            font-size: 20px;
            font-weight: 600;
            color: white;
          }
          .opening-hours {
            display: block;
            font-size: 12px;
            color: $lux-gray;
          }
          .email {
            font-size: 12px;
            color: $gold;
          }
        }
      }
    }
  }

  .links {
    ul {
      list-style-type: none;
      display: inline-block;
      padding: 0;
      margin: 0;
      > li {
        font-size: 12px;
        @include links($lux-gray, $gold); // helpers/_functions.scss
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }
  .block.newsletter {
    height: 100%;
    position: relative;

    padding-top: 0px;
    padding-bottom: 0px;

    .payment-methods {
      position: absolute;
      bottom: 0;
      ul {
        list-style: none;
        padding: 0;
        li {
          display: inline-block;

          &:not(:last-child) {
            margin-right: 20px;
            @media screen and (min-width: 1201px) {
              margin-right: 36px;
            }
          }
        }
      }
    }
  }

  .social-media {
    height: 88px;
    padding-top: 25px;
    text-align: center;
    .follow-us {
      position: relative;
      top: -30px;
      font-size: 16px;
      font-weight: 400;
      color: $gold;
    }
    > ul {
      list-style-type: none;
      display: inline-block;
      padding-left: 40px;
      @include social-icons(white, $black, $gold); // helpers/functions.scss
    }
  }

  .privacy-policy {
    //height: 41px;
    text-align: center;
    //line-height: 40px;
    line-height: 30px;
    white-space: nowrap;
    > ul {
      list-style-type: none;
      display: inline-block;
      margin-bottom: 0;
      > li {
        display: inline-block;
        font-size: 11px;
        @include links($lux-gray, $gold);
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}