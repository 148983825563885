.account {

  .block-dashboard-orders {

    .block-title {
      .action {
        margin-left: 15px;
        font-size: 14px;
      }
    }

    .action {
      &:nth-child(2):before {
        content: '';
        display: inline-block;
        height: 12px;
        margin: 0 10px;
        border-left: 1px solid #a6a6a6;
        vertical-align: -1px;
      }
    }
  }

  .block-dashboard-info {
    margin-bottom: 50px;
    margin-top: 40px;

    .box-information {
      float: left;
      clear: left;

      .box-actions {
        .action {
          &:first-child:after {
            content: '';
            display: inline-block;
            height: 12px;
            margin: 0 10px;
            border-left: 1px solid #a6a6a6;
            vertical-align: -1px;
          }
        }
      }
    }

    .box-newsletter {
      float: right;

      .box-content {
        font-size: 14px;
      }
    }
  }

  .block-dashboard-addresses {
    margin-bottom: 0;

    .block-title {
      .action {
        margin-left: 15px;
        font-size: 14px;
      }
    }

    .box-billing-address {
      float: left;
      clear: left;
    }

    .box-shipping-address {
      float: right;
    }
  }

}