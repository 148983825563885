.product-slide-content{
  position: relative;
  padding: 0 10px;
  .product-image-wrapper{
    display: block;
    height: 280px;
    width: 100%;
    margin: 20px 0;
    text-align: center;
    .product-image-photo{
      max-height: 100%;
      width: auto !important;
      margin: auto;
    }
  }
  .manufacturer{
    text-align: center;
    //font-weight: bold;
    font-size: 14px;
  }
  .product-name{
    text-align: center;
    font-size: 14px;
    //height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $ss-dark-gray;
    font-weight: lighter;
  }

  .product-reviews-summary {
    margin: 5px 0 0 0;
    font-size: 18px;
    .review-stars-gray {
      color: $gray;
      position: relative;
      display: inline-block;

      .review-stars-color {
        color: #f2a53c;
        position: absolute;
        top: 0;
        overflow: hidden;
      }
    }
    .review-count {
      display: none;
    }
  }

  .product-details {
    height: 20px;

    .content {
      display: none;
      color: $lux-gray;

      .year {
        display: block;
        float: left;
        text-align: left;
      }

      .state {
        display: block;
        float: right;
        text-align: right;
      }

      .green-text {
        color: $lux-green;
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }
  }

  @media screen and (min-width: $screen-sm) and (max-width: $screen-md) {
    .price-box {
      font-size: 18px;
      //margin: 8px 0;
      .price.currency{
        font-size: 13px;
      }
    }
    .product-item-download {
      font-size: 8px;
      .fa-download {
        font-size: 13px;
      }
    }
  }
  @media screen and (min-width: $screen-md) and (max-width: $screen-lg) {
    .price-box {
      font-size: 20px;
      //margin: 8px 0;
      .price.currency{
        font-size: 15px;
      }
    }
    .product-item-download {
      font-size: 9px;
      .fa-download {
        font-size: 13px;
      }
    }
  }

  @media screen and (min-width: $screen-lg)  {
    .price-box {
      font-size: 18px;
      //margin: 8px 0;
      .price.currency{
        font-size: 20px;
      }
    }
    .product-item-download {
      font-size: 10px;
      .fa-download {
        font-size: 13px;
      }
    }
  }
}

.switcher-slider-content-screen {
  #news-slider,
  #best-seller-slider,
  #best-rank-slider,
  #sale-slider,
  #recently-viewed-slider{
    .owl-item{
      text-align: center;

      &:hover {
        -webkit-box-shadow: 0 0 5px 4px rgba(0, 0, 0, .05);
        -moz-box-shadow: 0 0 5px 4px rgba(0, 0, 0, .05);
        box-shadow: 0 0 5px 4px rgba(0, 0, 0, .05);

        .product-details .content {
          display: block;
        }
      }
    }
    padding: 0 40px;
    .item {
      text-align: center;
      width: auto;
      display: inline-block;
      margin-bottom: 0;
    }

    .owl-stage-outer {
      overflow: hidden;
    }

    @media screen and (max-width: $screen-lg)  {
      .owl-item:hover {
        box-shadow: none;
      }
    }

    .owl-controls {
      width: 100%;
      position: absolute;
      top: 135px;
      left: 0;
      display: block ;
      .owl-nav {
        .owl-prev,
        .owl-next {
          position: absolute;
          display: inline-block;
          .nav-box {
            color: $lux-gray;
            width: 30px;
            height: 55px;
            text-align: center;
            position: relative;
            background-color: rgba(255, 255, 255, 0.6);
            > * {
              display: inline-block;
              margin-top: 20px;
              font-size: 12px;
            }
          }
        }
        .owl-prev {
          left: 0px;
        }
        .owl-next {
          right: 0px;
        }
      }
      .owl-dots {
        text-align: center;
        position: absolute;
        top: 265px;
        width: 100%;
        display: inline-block !important;
        .owl-dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $custom-blue;
          //display: inline-block;
          display: none;
          margin-right: 10px;
        }
        .owl-dot.active {
          display: none;
          background-color: $custom-red;
        }
      }
    }
  }
}
.switcher-slider-content-screen {
  #recently-viewed-slider{
    .owl-controls {
      .owl-nav {
        .owl-prev,
        .owl-next {
          .nav-box {
            width: 50px;
            height: 112px;
            > * {
              margin-top: 35px;
            }
          }
        }
        .owl-prev {
          left: 0px;
        }
        .owl-next {
          right: 0px;
        }
      }
      .owl-dots {
        .owl-dot {
        }
        .owl-dot.active {
        }
      }
    }
  }
}