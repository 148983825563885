body:not(.customer-account-create) {
  .account {

    .fieldset.info {
      float: left;
      clear: left;
      width: 48%;
    }

    .fieldset.password {
      float: right;
      display: none;
      width: 48%;
    }

    .actions-toolbar {
      clear: both;
      text-align: left;

      .primary {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        display: table;
      }

      &:after {
        clear: both;
        content: '';
        display: table;
      }
    }

  }
}