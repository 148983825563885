.account,
.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword {
  .block-title {
    margin-bottom: 25px;
    padding-bottom: 10px;

    strong {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .block-content {
    .box {
      width: 48%;

      .box-title {
        display: inline-block;
        margin: 0 0 10px;
        font-weight: 500;
        font-size: 14px;
      }

      .box-content {
        line-height: 24px;
        font-size: 16px;
        color: $ss-gray;
        font-weight: lighter;
      }

      .box-actions {
        margin-top: 5px;

        .action {
          color: #1979c3;
          font-size: 14px;
        }
      }
    }

    &:before {
      content: '';
      display: table;
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .empty {
    font-size: 16px;
    color: $ss-gray;
  }

  .legend {
    border-bottom: none !important;

    span {
      font-size: 14px !important;
      color: black !important;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 500 !important;
    }
  }
}

.account {
  .columns {
    margin-bottom: 80px;
  }
}