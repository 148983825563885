.account {

  #reminder-modal,
  #accept-license-modal {
    .modal-content {
      width: 50%;

      .info {
        font-size: 22px;
      }
    }

    .actions {
      .action {
        font-size: 16px;
      }
    }
  }

}