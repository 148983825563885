.btn {
  border: none;
  line-height: 2em;
  padding: 0 10px;
  display: inline-block;
}
.btn-red {
  color: white;
  background-color: $custom-red;
}
.btn-blue {
  color: white;
  background-color: $custom-blue;

  &:hover {
    color: white;
    text-decoration: none;
    background-color: $custom-darker-blue;
  }
}

.btn-gold {
  color: white !important;
  text-decoration: none !important;
  background-color: $gold;
  &:focus {
    color: white;
    text-decoration: none;
  }
}
.btn-gold-grad {
  @extend .btn-gold;
  background: url(../images/gradient_button.png);
  background-size: auto 100%;
  background-repeat: repeat-x;
  -webkit-box-shadow: 1px 1px 1px 0 rgba(50, 50, 50, 0.3);
  -moz-box-shadow:    1px 1px 1px 0 rgba(50, 50, 50, 0.3);
  box-shadow:         1px 1px 1px 0 rgba(50, 50, 50, 0.3);
}
.btn-gold-white {
  color: $gold !important;
  background-color: rgba(1, 1, 1, 0);
  border: 1px solid $gray;
  text-decoration: none !important;
  &:hover, &:focus {
    color: $gold !important;
    text-decoration: none;
    border-color: $gold;
  }
}
.btn-white-gold {
  color: $gold;
  background-color: rgba(1, 1, 1, 0);
}
.btn-gray {
  background-color: $lighter-gray;
  color: $dark-gray;

  &:hover {
    color: $dark-gray;
    background-color: $gray;
    text-decoration: none;
  }
}

.chevron::before {
	border-style: solid;
	border-width: 0.20em 0.20em 0 0;
	content: '';
	display: inline-block;
	height: 1.1em;
	position: relative;
	top: 0.20em;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
	vertical-align: top;
	width: 1.1em;
}

.chevron.right:before {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}


.chevron.left:before {
    -ms-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

@media screen and (min-width: 768px){

.chevron::before {
    border-style: solid;
    //border-width: 0.45em 0.45em 0 0;
    content: '';
    display: inline-block;
    height: 2.2em;
    position: relative;
    top: 0.4em;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    vertical-align: top;
    width: 2.2em;
}

.chevron.right:before {
    right: 8px;
}


.chevron.left:before {
    left: 8px;
}
  .underline {
    border-bottom: 1px solid $gold;
  }
}