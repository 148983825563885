.cms-page-view {
  .column {
    width: 100%;

    .sidebar {
      float: left;
      width: 30%;
    }

    .content {
      float: right;
      width: 70%;
    }
    .text-image-widget-container{
      .text-image-widget{
        .content-box{
          .content{
            width: 100%;
          }
        } 
        .image-box{
          img{
            width: 100%;
          }
        }
      }
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}