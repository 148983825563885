
.products-grid {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 70px;
}

div.products.wrapper {
  .products {
    padding: 0;
    list-style: none;
    @media screen and (min-width: 996px) {
      li.hr {
        border-bottom: 1px solid $lighter-gray;
      }
    }
    hr{
      width: 100%;
      margin: 0;
    }

    .product-item {
      text-align: center;
      padding: 0;
      &:hover {
        -webkit-box-shadow: 0 0 5px 4px rgba(0, 0, 0, .05);
        -moz-box-shadow: 0 0 5px 4px rgba(0, 0, 0, .05);
        box-shadow: 0 0 5px 4px rgba(0, 0, 0, .05);
      }
      .product-item-info {
        padding: 35px 20px;
        display: block;
        border: none;
        text-align: center;

        height: auto;
        .product-image-price-container {
          position: relative;
          height: 250px;
          .price-image-wrapper {
            .product-item-photo {
              padding: 0;
              .product-image-container {
                display: inline-block;

                .product-image-wrapper {
                  display: block;
                  position: relative;
                  z-index: 1;
                  overflow: hidden;
                  .product-image-photo {
                    max-height: 240px;
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }
        .product-item-details {
          margin-top: 15px;

          @media screen and (min-width: 787px) and (max-width: 991px){
            text-align: center;
          }

          .product-item-name-manufacturer {
            .product-item-manufacturer {
            }
            .product-item-name {
              font-size: 14px;
              line-height: 20px;
              white-space: normal;
              overflow: hidden;
              height: 40px;
            }

            .product-reviews-summary {
              font-size: 18px;
              .review-stars-gray {
                color: $gray;
                position: relative;
                display: inline-block;

                .review-stars-color {
                  color: #f2a53c;
                  position: absolute;
                  top: 0;
                  overflow: hidden;
                }
              }
              .review-count {
                font-size: 0.8em;
                a {
                  color: inherit;
                  text-decoration: underline;
                }
              }
            }
          }

          .product-item-inner {
            margin-top: 10px;
            .product-item-description {
              font-size: 14px;
              color: $darker-gray;
              font-weight: 400;
              line-height: 20px;
              margin: 0 0 27px 0;
              text-overflow: ellipsis;
            }
            a.toproduct {
              padding: 5px 40px;
              font-size: 13px;
              &:hover, &:active, &:focus {
                color: white;
                text-decoration: none;
              }
            }
          }

          .product-item-download {
            margin-top: 10px;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: bold;

            .fa-download {
              color: $custom-green;
              font-size: 13px;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  .price-box {
    position: relative;
    bottom: auto;

    .old-price {
      font-size: 11px;
      position: absolute;
      transform: translateX(-115%);
      padding-top: 3px;

      .price-wrapper {
        text-decoration: line-through;
      }
    }
  }
}