.page-products {
  .container {
    .breadcrumbs-container {
      padding-left: 0 !important;
    }
  }
}

.checkout-cart-index{
  .breadcrumbs-container{
    display: block;
  }
}