@media only screen and (min-width: 992px) {
  .checkout-index-index {
    .opc-wrapper,
    .opc-sidebar {
      float: left;
      margin-top: 15px;
    }

    .opc-wrapper {
      width: 66.67%;
    }

    .opc-sidebar {
      width: 33.33%;
      margin-bottom: 30px;
    }

    .iwd-checkout-shipping-address {
      float: left;
      width: 47.8%;
      margin-right: 2.2%;
    }

    .iwd-opc-shipping-method,
    .iwd-checkout-payment-method {
      width: 49.7%;
      overflow: hidden;
      padding: 20px 30px;
    }

    .iwd-opc-shipping-method {
      margin-bottom: 18px;
    }
  }
}