div.minicart-wrapper {
  vertical-align: middle;
  top: 0;
  right: 0;
  a.showcart {
    div.img {
      width: 24px;
      height: 25px;
      background-image: url(../images/header/mini-cart.png);
      background-size: cover;
      margin: 0 auto;
      margin-bottom: 5px;
    }
    span.counter {
      padding: 0 5px;
      background: $gold;
      border-radius: 1em;
      font-size: 11px;
      color: white;
      position: absolute;

      border: 2px solid white;
      span.counter-number {
        line-height: 1.5em;
      }
    }
  }
  .ui-dialog.ui-widget.ui-widget-content {
    #minicart-content-wrapper {
      right: 30px;
      left: initial;
      margin: initial;
    }
  }
}
