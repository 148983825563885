.badges {
  position: absolute;
  display: block;
  right: 0;
  z-index: 2;
  text-align: right;
  .badge {
    text-transform: uppercase;
    color: white;
    font-size: 14px;
    display: block;
    text-align: center;
    margin-bottom: 3px;
  }

  .badge-essential {
    width: 84px;
    background-color: $light-black;
  }
  .badge-neu {
    width: 45px;
    background-color: $light-black;
  }
  .badge-sale {
    width: 45px;
    background-color: $lux-red;
  }
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}