.checkout-cart-container {
  .page-title-wrapper {
    display: inline-block;
    .page-title {
      font-size: 36px;
      margin-bottom: 30px;
      margin-top: 25px;
      padding: 0;
    }
  }
  .cart-items-count-box {
    display: none;
    .cart-items-count {
    }
    .cart-items-price {
    }
  }
  .form.form-cart {
    position: relative;
    padding-bottom: 65px;
  }
  #block-shipping {
    display: none;
  }
  .checkout-methods-items {
    margin: 0;
    list-style: none;
    padding: 0;
    float: right;
    .action.primary.checkout {
      width: 290px;
      margin: 25px 0 0 0;
    }
  }
  .cart.main.actions {
    position: relative;
    width: 100%;
    top: 37px;
    text-align: right;
    .action.update {
      max-width: 300px;
      height: 35px;
      text-transform: uppercase;
      border: none;
      font-weight: bold;
      color: $ss-dark-gray;
      background-color: $lighter-gray;
      font-size: 14px;
      padding: 0;
      i {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
  #shopping-cart-table {
    width: 100%;
    margin-top: 10px;
    .table-caption {
      display: none;
    }
    thead {
      tr {
        background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/blue-pattern.png');
        background-size: 40px 23px;
        text-transform: uppercase;
        letter-spacing: 1px;
        > * {
          padding: 15px 0;
          font-size: 14px;
          color: $ss-dark-gray;
          font-weight: 500;
        }
        .col.item_name {
          text-align: left;
        }
        .col.price {
          text-align: center;
        }
        .col.qty {
          text-align: center;
        }
        .col.subtotal {
          text-align: center;
        }
      }
    }
    tbody.cart.item {
      .item-info {
        border-bottom: 2px solid #ccc;
        .col {
          padding-top: 5px;
        }
        .col.item_img {
          min-width: 100px;
          .product-image-wrapper {
            display: block;
            padding: 10px 15px;
            height: 180px;
            text-align: center;
            .product-image-photo {
              max-height: 100%;
              max-width: 100%;
            }
          }
        }
        .col.item_name {
          max-width: 450px;
          vertical-align: top;
          padding-top: 20px;
          .product-item-details {
            a {
              color: $ss-dark-gray;
            }
            a:hover {
              text-decoration: none;
              color: $ss-dark-gray;
            }
            > * {
            }
            .product-item-manufacturer {
              display: block;
              color: $ss-dark-gray;
              text-transform: uppercase;
              font-size: 14px;
            }
            .product-item-name {
              display: block;
              font-size: 14px;
            }
            .product-item-sku {
              display: none;
            }
            .attributes-table {
              margin-top: 10px;
              font-size: 14px;
              .attributes-table__name {
                color: $lux-gray;
              }
              .attributes-table__value {
                padding-left: 15px;
              }
              td {
                padding-top: 2px;
                padding-bottom: 2px;
              }
            }
          }
        }
        .col.item_remove {
          padding: 0 15px;
          line-height: 1.0;
          .action-edit {
            display: none;
          }
          .action-delete {
            font-size: 20px;
            color: $lux-gray;
            float: right;
            text-decoration: none;
            &:hover {
              text-decoration: none;
            }

            span {
              font-size: 14px;
              vertical-align: middle;
            }
          }
        }
        .col.price,
        .col.qty,
        .col.subtotal {
          .price-excluding-tax {
            color: $ss-dark-gray;
          }
          .cart-col-item-title {
            display: none;
          }
        }
        .col.price {
          max-width: 120px;
          padding: 0 15px;
          text-align: center;
          .cart-price {
            font-size: 16px;
          }
        }
        .col.qty {
          max-width: 200px;
          //padding: 0 20px;
          text-align: center;
          .field.qty {
            display: inline-block;
            text-align: center;
            .qty-box {
              display: inline-block;
              height: 50px;
              color: $ss-dark-gray;
              font-size: 0;
              .input-text.qty, .qty-btn {
                display: inline-block;
                font-size: 22px;
                height: 100%;
                text-align: center;
                vertical-align: middle;
              }
              .input-text.qty {
                width: 49%;
                padding: 5px;
              }
              .qty-btn  {
                width: 33%;
                padding: 5px 12px 5px 5px;
              }

              .qty-input-wrapper {
                position: relative;
                width: 70px;
                border: 1px solid #ccc;
              }

              .qty-btn {

                .qty-plus,
                .qty-minus {
                  position: absolute;
                  color: $gold;
                  line-height: 17px;
                }
                i.qty-plus {
                  top: 2px;
                }

                i.qty-minus {
                  bottom: 2px;
                }
              }

              .qty-plus:hover,
              .qty-minus:hover {
                cursor: pointer;
              }

              .input-text.qty {
                font-size: 18px;
                color: $ss-dark-gray;

                &:focus {
                  outline: none;
                }
              }

              .input-text.qty.qty-center{
                border: none;
                background-color: transparent;
                text-align: center;
                -moz-appearance: textfield;
              }
              .input-text.qty::-webkit-inner-spin-button,
              .input-text.qty::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
        }
        .col.subtotal {
          max-width: 160px;
          text-align: right;
          .cart-price {
            font-size: 16px;
          }
        }
      }
    }
  }

  .coupon-box {
    position: relative;
    top: -65px;
    width: 50%;
    float: left;
    max-width: 560px;
    display: inline-block;
    padding: 30px 0;
    .coupon-title {
      display: none;
    }
    .coupon-hint {
      text-align: left;
      padding-left: 0;
    }
    #discount-coupon-form {
      .fieldset.coupon {
        width: 55%;
        //width: 400px;
        height: 35px;
        border: 1px dashed #ccc;
        font-size: 0;
        position: relative;
        .control {
          width: 90%;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          #coupon_code {
            width: 100%;
            height: 100%;
            border: none;
            font-size: 15px;
            padding-left: 20px;
          }
        }
        .actions-toolbar {
          position: absolute;
          width: 40px;
          height: 90%;
          right: 2px;
          margin: 2px 0;
          .primary {
            width: 100%;
            height: 100%;
            .action.primary {
              width: 100%;
              height: 100%;
              background-color: $gold;
              color: white;
              font-size: 18px;
              text-transform: uppercase;
              border: none;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $screen-md) {
    .coupon-box {
      max-width: 400px;
      .coupon-title {
      }
      .coupon-hint {
      }
      #discount-coupon-form {
        .fieldset.coupon {
          .control {
            width: 55%;
            #coupon_code {
            }
          }
          .actions-toolbar {
            width: 40px;
            .primary {
              .action.primary {
              }
            }
          }
        }
      }
    }
  }

  #cart-totals {
    text-align: right;
    position: initial;
    top: 0;
    display: block;
    width: 50%;
    float: right;
    .table-wrapper {
      width: 100%;
    }
    .data.table.totals {
      display: block;
      width: 100%;
    }
    .table-caption {
      display: none;
    }
    tbody {
      font-size: 14px;
      color: #000000;
      width: 450px;
      display: block;
      float: right;
      tr {
        margin-bottom: 10px;
        display: block;
        width: 100%;
        float: left;
        text-align: left;
        text-transform: uppercase;
        color: $lux-gray;
        font-weight: lighter;
        letter-spacing: 1px;

        td {
          float: right;
          color: $ss-dark-gray;
        }
      }
      .mark {
        background-color: transparent;
        padding: 0;
        font-weight: normal;
        display: inline-block;
      }
      .mark:after {
        content: ":";
      }
      .amount {
        font-weight: normal;
        display: inline-block;
        text-align: right;
      }
      .grand.totals {
        font-size: 16px;
        border-top: 2px solid #ccc;
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 10px;

        .mark {
          color: $ss-dark-gray;

          strong {
            font-weight: 500;
          }
        }

        .price {
          font-size: 26px;
          font-weight: 600;
        }
      }
    }
  }
  #cart-totals + .checkout.methods.items.checkout-methods-items {
    position: initial;
    top: 0;
    width: 100%;
    text-align: right;
    margin-bottom: 70px;
    border-top: 2px solid #ccc;
  }

  :not(.page-title-wrapper) + .checkout-methods-items {
    .item {
      float: left;
      width: 50% !important;
    }
  }

  .continue {
    width: 290px;
    text-decoration: none;
  }
}
