.account,
.customer-account-login,
.customer-account-create {
  .table-wrapper {
    margin-bottom: 0;
    margin-top: -25px;

    .table {
      margin-bottom: 0;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      max-width: 100%;

      .table-caption {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        border-bottom: 1px solid #c6c6c6;

        .col {
          padding: 11px 10px;
          vertical-align: bottom;
          text-align: left;
          font-size: 14px;

          &.actions {
            font-size: 14px;
          }
        }
      }
    }
  }
}