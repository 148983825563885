div.block.block-search {
  width: initial;
  float: left;
  margin-top: 19px;
  margin-bottom: 0;
  #search_mini_form {
    .field.search {
      width: initial;
      input#search {
        width: 250px;
      }
    }
  }
}