.block.filter {
  .filter-title {
    float: left;
  }
  .block-content.filter-content {
    margin-left: 150px;
    #narrow-by-list {
      .filter-option {
        padding: 0 7px;
      }
    }
  }
  .filter-content {
    ul.filter-options {
      .filter-option {
        .filter-options-content {
          left: 0;
        }
      }
    }
  }
}