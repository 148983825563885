div.toolbar.toolbar-products {
  .toolbar-container {
    display: block;
    padding: 10px 15px;

    label {
      display: inline;
    }
    div.field.limiter {
      display: inline-block;
      float: left;
      label {
        margin-right: 7px;
      }
      div.control {
        display: inline-block;
        padding: 0;
        width: 160px;
        select {
          margin-right: 7px;
        }
      }
    }

    div.toolbar-sorter.sorter {
      display: inline-block;
      float: right;
      label {
        margin-right: 7px;
      }
      div.control {
        padding: 0;
        display: inline-block;
        width: auto;
        select {
          width: 115px;
        }
      }
    }

    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
}