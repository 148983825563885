div.blog-cat-header {
  div.blog-category-description {
    background-color: black;

    .description-content {
      font-size: 13px;
      height: 72px;
      line-height: 180%;
    }
    h1 {
      margin: 20px 0 27px;
      font-size: 48px;
    }
  }
}