.comments-wrapper {
  .comment-input {

    #comment_user_name{
      border: 1px solid #e3e3e3;
      padding: 4px 12px;
      width: auto;
    }

    #add-comment-btn {
      display: inline-block;
      top: 0;
      right: 0;
      position: absolute;
      width: auto;
    }
  }
}