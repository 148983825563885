.catalog-product-view{
  .fotorama__nav-wrap {
    .fotorama__nav {
      overflow: visible;

      .fotorama__nav__shaft {
        display: block;

        .fotorama__nav__frame--thumb {
          &:not(:first-child) {
          }
        }
      }
    }
    .fotorama__thumb__arr--right {
      display: block;
      margin-top: 7px;
      margin-left: 0;
      .fotorama__thumb--icon {
        position: absolute;
        top: -9px;
        bottom: 0;
        left:0;
        right: 0;
        padding: 0;
        margin: auto;
        width: 20px;
        height: 20px;
      }
    }
  }
}