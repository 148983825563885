.posts-slider.blog-box{
  #blog-posts-slider{
    margin-top: 25px;
    margin-bottom: 80px;
    padding: 0 60px;
    .item{
      margin: 0 3px;
      .posts-slider-content{
        .bg-img{
          height: $consul-l-height;
        }
        .text-box{
          height: $consul-tb-l-height;
          padding: 20px 15px;
          .title{
            font-size: 12px;
            margin-bottom: 10px;
          }
          .description{
            font-size: 20px;
            line-height: 1;
          }
        }
      }
    }

    .owl-controls{
      top:100px;
      .owl-nav{
        .owl-prev,
        .owl-next{
          .nav-box{
            width: 50px;
            height: 112px;
            > *{
              margin-top: 32px;
            }
          }
        }
        .owl-prev{
        }
        .owl-next{
        }
      }
      .owl-dots{
        top: 230px;
        .owl-dot{
        }
        .owl-dot.active{
        }
      }
    }

  }
}