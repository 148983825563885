.text-image-widget-container {

  .text-image-widget-title {
    font-size: 20px;
  }
  > div {
    margin-bottom: 0 !important;
  }
  .text-image-widget {
    margin-left: 0;
    margin-right: 0;

    .title-box {
      .title {
        font-size: 18px;
        line-height: 1;
      }
    }
    .image-box {
      width: 50%;
      margin: 0;
    }
    .content-box {
      width: 50%;
      .content {
        line-height: 1.8;
        @media screen and (max-width: 1200px){
          padding: 20px;
        }
        padding: 35px 40px;
      }
    }
  }
}