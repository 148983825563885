select.liz-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url(../images/category/gold-down-arrow.png);
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-radius: 0;
  -o-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding-left: 10px;
  border: 2px solid $gray;
  padding-right: 40px;
  height: 30px;
  line-height: 30px;
  font-weight: 300;
  color: $lux-gray;
  background-color: white;
}