.sub-cat-container {
  padding: 0 !important;
}

.sub-cat-category-view {
  margin-bottom: 8px;
  @media screen and (min-width: $screen-sm) and (max-width: $screen-md) {
    .sub-cat-item-col:nth-child(2n+1){
      padding: 0 7px 0 15px;
    }
    .sub-cat-item-col:nth-child(2n+2){
      padding: 0 15px 0 7px;
    }
  }
  @media screen and (min-width: $screen-md) {
    .sub-cat-item-col:nth-child(4n+0){
      padding: 0 15px 0 7px;
    }
    .sub-cat-item-col:nth-child(4n+1){
      padding: 0 7px 0 15px;
    }
    .sub-cat-item-col:nth-child(4n+2){
      padding: 0 7px 0 7px;
    }
    .sub-cat-item-col:nth-child(4n+3){
      padding: 0 7px 0 7px;
    }
  }
}