.advanced-slider-widget{
  .icon_slider{
    margin: 22px 0 50px 0;
    padding: 0 60px;
    .item{
      text-align: center;
      .icon-slide-content{
        display: inline-block;
        text-align: center;
        .base-icon{
          display: block;
          height:70px;
          img{
            max-height: 70px;
          }
        }
        .base-icon-hover{
          display: none;
          height: 70px;
          img{
            max-height: 70px;
          }
        }
        .title{
          font-size: 12px;
          margin-top: 14px;
          color: $lux-gray;
        }
      }
      .icon-slide-content:hover{
        .base-icon{
          display: none;
        }
        .base-icon-hover{
          display: block;
        }
      }
    }
    .owl-controls{
      top:15px;
      .owl-nav{
        .owl-prev,
        .owl-next{
          .nav-box{
            width: 50px;
            > *{
            }
          }
        }
        .owl-prev{
        }
        .owl-next{
        }
      }
      .owl-dots{
        top:105px;
      }
    }
  }
}