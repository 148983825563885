#mini-contact {
  min-height: 40px;
  min-width: 185px;
  margin-right: 28px;
  text-align: left;
  div.avatar {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    background-image: url(../images/header/contact-avatar.png);
    background-size: cover;
    margin-right: 7px;
    vertical-align: middle;
    .available, .not-available {
      position: absolute;
      bottom: 3px;
      right: -1px;
      width: 12px;
      height: 12px;
      border-radius: 13px;
      border: 1px solid white;
    }
    .available {
      background-color: $custom-green;
    }
    .not-available {
      background-color: $lux-red;
    }
  }
  .contact-details {
    display: inline-block;
    vertical-align: middle;

    .call-center {
      font-size: 10px;
    }

    .call-center-info {
      font-size: 10px;
      color: black;
    }

    p {
      margin: 0;
    }
    p.telephone-number {
      font-size: 15px;
      line-height: 14px;
      font-weight: 500;
      margin-bottom: 0;
      color: black;
    }
  }
}