.advanced-slider-widget{
  .consul_slider{
    padding: 0 25px;
    .item{
      margin: 0 2px;
      .consul-slider-content{
        .bg-img{
          height: $consul-l-height;
        }
        .text-box{
          height: $consul-tb-l-height;
          padding: 20px 15px;
          .title{
            font-size: 16px;
            margin-bottom: 10px;
          }
          .description{
            font-size: 22px;
          }
        }
      }
    }

    .owl-controls{
      top:100px;
      .owl-nav{
        .owl-prev,
        .owl-next{
          .nav-box{
            width: 50px;
            height: 112px;
            > *{
              margin-top: 32px;
            }
          }
        }
        .owl-prev{
        }
        .owl-next{
        }
      }
      .owl-dots{
        top: 230px;
        .owl-dot{
        }
        .owl-dot.active{
        }
      }
    }

  }
}