.express-delivery-info {
  padding-bottom: 40px;

  .main-title {
    font-size: 20px;
    padding: 50px 0;
  }
  .info-box {
    width: 25%;
      margin-bottom: 0;
    .image-wrapper {
      width: 100%;

      .image {
        width: 120px;
        height: 120px;
        .icon {
          @include scale(.6);
          margin: 0;
          bottom: 19px;
          left: 13px;
        }
        &:before {
          left: 85px;
          width: 30px;
          height: 30px;
          padding: 4px 0 0 2px;;
        }
      }
    }
    .right-arrow {
      display: block;
      position: absolute;
      top: 45px;
      width: 12%;
      font-size: 13px;
      color: white;
      .fa:not(:last-child) {
        margin-right: 15px;
      }
      .fa:last-child {
        position: relative;
        top: 5px;
        left: -2px;
        font-size: 26px;
      }
    }
    .content {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      margin-left: 0;

      .title,
      .description {
        font-size: 12px;
      }
      .title {
      }
      .description {
        padding: 0 15px;
        line-height: 1.8;
      }

    }
  }
  @for $i from 1 to 5 {
    .info-box:nth-child(#{$i}) {
      .right-arrow {
        @if $i == 1 { // The first arrow
          left: 235px;
        } @else if $i == 2 { // The second arrow
          left: 520px;
        } @else if $i == 3 { // The second arrow
          left: 805px;
        }
      }
    }
  }
}

@media (max-width: 1210px) {
  .express-delivery-info {
    .right-arrow {
      display: none !important;
    }
  }
}