// Bootstrap
@import "source/bootstrap";

// Font Awesome
@import "source/font-awesome";

// Helpers
@import "source/helpers/variables";
@import "source/helpers/functions";
@import "source/helpers/icons";
@import "source/helpers/badges";
// Sprites
@import "source/sprites/sprite.css";

// Modules
//@import "../../Magento_Newsletter/web/css/source/newsletter";

//Buttons
@import "source/helpers/buttons";

//Selects
@import "source/helpers/selects";

// Components
@import "source/components/express_delivery_info-l";
@import "source/components/text-image-widget-l";
@import "source/components/breadcrumbs-l";
@import "source/components/section_title_l";

//Header
@import "../../Magento_Theme/web/scss/header-l";
@import "../../Magento_Search/web/scss/search-mini-form-l";
@import "../../../../../../code/Madcoders/Contact/view/frontend/web/scss/contact-mini-l";
@import "../../Magento_Checkout/web/scss/mini-cart-l";

// Layout
@import "source/layout/header/expand-navbar-l";
@import "source/layout/header/expand-navbar-fixed-top-l";
@import "source/layout/footer/footer-l";
@import "source/layout/header/mega-menu-item-l";

//Layout - Homepage
@import "source/layout/homepage/slider_container-l";
@import "../../../../../../code/Madcoders/SlidersSwitcher/view/frontend/web/scss/switcher-l";
@import "../../../../../../code/Madcoders/SlidersSwitcher/view/frontend/web/scss/slider/product_slider-l";

//Advanced Sliders Styles
@import "../../../../../../code/Madcoders/AdvSlider/view/frontend/web/scss/icon_slider-l";
@import "../../../../../../code/Madcoders/AdvSlider/view/frontend/web/scss/consul_slider-l";
@import "../../../../../../code/Madcoders/AdvSlider/view/frontend/web/scss/banner_slider-l";

////Banner Slider
//@import "../../../../../../code/Madcoders/Sliders/view/frontend/web/scss/banner-slider-l";

//Category Page
@import "../../Magento_Catalog/web/scss/_category-description-l.scss";
@import "../../Magento_Catalog/web/scss/_category-toolbar-l.scss";
@import "../../Magento_Catalog/web/scss/_category-grid-l.scss";
@import "../../Magento_Catalog/web/scss/_category-table-contents-l.scss";
@import "../../../../../../code/Madcoders/CategoryGroup/view/frontend/web/scss/sub-cat-l";

//Filters
@import "../../Manadev_LayeredNavigation/web/scss/filters-l";
// FAQ widget
//@import "../../../../../../code/Madcoders/FaqWidget/view/frontend/web/scss/faq-l";

//Product Page
@import "../../Magento_Catalog/web/scss/catalog_product_view-l";
//@import "../../../../../../code/Madcoders/Highlights/view/frontend/web/scss/highlight-l";
@import "../../Magento_Catalog/web/scss/product_description-l";
@import "../../Magento_Catalog/web/scss/product_gallery-l";

// Reviews
//@import "../../../../../../code/Madcoders/Review/view/frontend/web/scss/review-l";

// Customer Area
@import "../../../../../../code/Madcoders/Customer/view/frontend/web/scss/customer_l";
//@import "../../Magento_Customer/web/source/resetpassword_l";


// Blog
@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/category/cat_head-l";
@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/blog_post_l";
@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/blog_comments_l";

// Blog slider
@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/slider/posts_slider-l";

//Cart Page
@import "../../Magento_Checkout/web/scss/cart-page-l";

// Checkout
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/base/grid-l";

// Category Page Blog Posts
//@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/catalog/blog_cat_post_l";

// Cms pages
@import "../../Magento_Cms/web/scss/cms_l";
@import "../../../../../../code/Madcoders/Cms/view/frontend/web/scss/cms_page-l";

// Map Widget
@import "../../../../../../code/Madcoders/MapWidget/view/frontend/web/scss/map-widget-l";

// Important note widget
@import "../../../../../../code/Madcoders/ImportantNoteWidget/view/frontend/web/scss/important_note-l";

// LP Widget
@import "../../../../../../code/Madcoders/LandingPageWidget/view/frontend/web/scss/lp-widget-l";

body {
  @include placeholder($lux-gray); // helpers/_functions.scss

  .page-main {
    position: relative;
    margin-bottom: -35px;
  }
}

a[name] {
  display: block;
  position: relative;
  top: -200px;
  visibility: hidden;
}

.payment-method-note{
  margin-bottom: $line-height-computed;
}