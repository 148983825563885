header {
  .financing-info {
    display: inline-block;
    float: left;
    position: relative;
    top: 17px;
    left: 10px;
    color: $lux-green;
    font-weight: lighter;

    .percent {
      display: block;
      width: 35%;
      float: left;
      font-size: 26px;
    }

    .label {
      display: block;
      width: 65%;
      float: right;
      position: relative;
      top: 6px;
      text-align: left;
      font-size: 11px;
      line-height: 1;
    }

    a {
      color: black;
    }

    i {
      color: $lux-gray;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .logo {
    position: relative;
    left: 50px;
    img {
      height: 74px;
    }
  }
  div.panel.header {
    #top-info-contact {
      display: none;
    }
    #top-info {
      display: block;
      padding-top: 7px;
      line-height: 1em;
      background-color: $super-light-blue;
      .marker {
        i.fa-map-marker {
          font-size: 14px;
          vertical-align: middle;
          position: relative;
          bottom: 1px;
          margin-right: 4px;
        }
        span {
          vertical-align: middle;
        }
        text-align: right;
        a, a:hover {
          color: $light-black;
        }
      }
      ul {
        padding: 0;
        li {
          list-style: none;
          display: inline-block;
          i {
            margin-right: 5px;
            color: black;
          }
          a {
            text-transform: none;
          }
          &:not(:last-child) {
            margin-right: 30px;
          }
        }

      }
    }
  }
  div.header.content {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    div.mobile-nav {
      display: none;
    }
    div.links {
      display: inline-block;
      float: right;
      width: auto;
      margin-top: 7px;

      &:after {
        display: block;
        content: "";
        clear: both;
      }
      & > div {
        display: inline-block;
      }
      .account-link {
        position: static;
        margin-right: 28px;
        display: inline-block;
        a {
          display: block;
          div.img {
            width: 25px;
            height: 25px;
            margin: 0 auto;
            background-size: cover;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}



.sticky-header {
  position: relative;
  top: -5px;
  transition: all .5s ease;
}

@media (max-width: $screen-md) {
  header {
    div.panel.header {
      #top-info {
        ul {
          li:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
