.expand-navbar {
  background-color: $light-black;
  position: relative;
  .mega-menu {
    list-style: none;
    //position: relative;
    min-height: 40px;
    margin-bottom: 0;
    padding-left: 0;
    > li {
      display: inline-block;
      padding: 11px 0 7px 0;
      border-bottom: 3px solid $light-black;
      &:not(:last-child) {
        margin-right: 60px;
      }
      > a {
        text-decoration: none;
        padding: 10px 0;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 500;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .mega-menu-item-container {
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        visibility: hidden;
        z-index: 100;
      }
      .mega-menu-item {
        @extend %mega-menu-item-l;
      }
      &:not(.hide-menu):hover { // Show mega menu
        border-bottom: 3px solid $gold;
        .mega-menu-item-container {
          visibility: visible;
        }
        .mega-menu-item {
          visibility: visible;
          overflow: visible;
          opacity: 1;
        }
      }
    }
  }
}

.sticky-expand-navbar {
  margin-top: 0!important;
  transition: all .5s ease;
}